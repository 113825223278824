<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col cols="12">
          <b-form-group label="Dil" description="Eklenecek veya güncellenecek dili seçiniz">
            <v-select
              v-model="defaultDil"
              :options="diller"
              :reduce="(diller) => diller.lang"
              label="title"
              :clearable="false"
              :disabled="!form._id"
              class="invoice-filter-select d-block"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Başlık">
              <b-form-input
                class="rounded-0"
                ref="baslik"
                v-model="form.icerik[defaultDil].baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />

              <b-form-invalid-feedback id="input-1-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-1">
            <label for="statu" class="font-weight-bold h6">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button squared block type="submit" :variant="form._id == null ? 'primary' : 'warning'">
            <feather-icon icon="CheckSquareIcon" class="mr-50" />
            <span class="align-middle">{{ form._id == null ? 'Ekle' : 'Güncelle' }}</span>
          </b-button>
          <b-button squared block type="submit" v-if="form._id" @click="resetForm">
            <feather-icon icon="CheckSquareIcon" class="mr-50" />
            <span class="align-middle">iptal</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { ref, toRefs, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
export default {
  components: {
    vSelect,
  },
  props: {
    politikalar: {
      type: Array,
      required: true,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },

  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);

    expo.form = ref({
      _id: null,
      k_no: null,
      icerik: {},
      statu: true,
    });

    const createIcerikDilUret = () => {
      expo.diller.value.forEach((dil) => {
        if (!expo.form.value.icerik.hasOwnProperty(dil.lang)) {
          expo.form.value.icerik[dil.lang] = { baslik: null };
        }
      });
    };

    createIcerikDilUret();

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.resetForm = () => {
      expo.form.value = {
        _id: null,
        k_no: null,
        icerik: {},
        statu: true,
      };
      createIcerikDilUret();
    };
    expo.onSubmit = () => {
      const basrUrl = expo.form.value._id == null ? 'iptalPolitikaEkle' : 'iptalPolitikaGuncelle';
      store
        .dispatch(basrUrl, expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            context.emit('refresh', res.data.data);
            if (expo.form.value._id == null) {
              expo.resetForm();
            }
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: err.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    let updateData = toRefs(props).updateData;
    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length > 0) {
          expo.form.value = val;
          createIcerikDilUret();
        }
      },
      { deep: true }
    );

    return { ...expo };
  },
};
</script>

<style lang="scss" scoped></style>
